import React, { useState } from 'react'
import { useRef } from 'react';
import emailjs, { send } from 'emailjs-com'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import styles from './styles.module.css'


export const ContactUs = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')
    const form = useRef();

    const templateParams = {
        to_name: 'Cepma',
        from_name: name,
        name: name,
        email: email,
        telefone: telefone,
    }

    const enviarEmail = (e) => {
        e.preventDefault();

        if (name === '' || email === '' || telefone === '') {
            alert("Preencha todos os campos")
            return;
        }

        emailjs.send('service_30hs51b', 'template_hyz40b4', templateParams, 'j4ljLNV5joOOCCBn9').then(
            (response) => {
                console.log('EMAIL ENVIADO', response.status, response.text)
                setName('')
                setEmail('')
                setTelefone('')

            }, (err) => {
                console.log('error', err)
            }
        )
    };


    return (
        <section className={styles['container']}>
            <div className={styles['form']}>
                <span className={styles['title-form']} style={{ fontFamily: 'Century Gothic, sans-serif' }}>Envie um email, em breve Entraremos em contato</span>
                <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={() => { }} >

                    <TextField
                        id="nome"
                        label="Nome Completo"
                        variant="standard"
                        type='text'
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        helperText="Coloque aqui o seu Nome"
                        required
                    />
                    <TextField
                        id="email"
                        label="Email"
                        variant="standard"
                        type='email'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        helperText="Email para contato"
                        required
                    />
                    <TextField
                        id="telefone"
                        label="Telefone"
                        variant="standard"
                        type='number'
                        onChange={(e) => setTelefone(e.target.value)}
                        value={telefone}
                        size='medium'
                        helperText="Telefone para contato"
                        required
                    />
                    <br /><br />
                    <Button
                        color="success"
                        variant="contained"
                        endIcon={<SendIcon />}
                        type='submit'
                        value="Enviar"
                        onClick={enviarEmail}
                    >
                        Enviar
                    </Button>
                    <input type="hidden" name="accessKey" value="06f86342-8ae4-4138-9fb6-ec6d56b46666" />
                    <input type="hidden" name="subject" value="Contact us from - example.com" />
                    <input type="hidden" name="replyTo" value="@" />
                    <input type="hidden" name="redirectTo" value="https://example.com/contact/success" />
                </form>
            </div>
            <div className={styles['cont-txt']}>
                <span className={styles['title']}>AGENDE UMA VISITA</span>
                <span className={styles['txt1']} >SERÁ UM PRAZER RECEBER VOCÊ</span>
                <span className={styles['txt1']}> NO CEPMA.</span>
                <span className={styles['txt2']}> Envie um email pelo formulário ao lado ou entre em contato conosco pelo nosso Wats App</span>
            </div>
        </section>
    )
}
