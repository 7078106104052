import React from 'react'
import styles from './styles.module.css'

export default function Bloco6() {
    return (
        <section className={styles['container']}>
            <div className={styles['cont-title']}>
                <span className={styles['title']}>Modalidades</span>
                <span className={styles['sub-title']}>Oferecidas:</span>
            </div>
            <div className={styles['cont-bloco']}>
                <div className={styles['cont-txt']}>
                    <span className={styles['var1']}>Ensino Bilíngue</span>
                    <span className={styles['var2']}>Karatê</span>
                    <span className={styles['var3']}>Robótica</span>
                </div>
                <div className={styles['cont-txt']}>

                    <span className={styles['var4']}>Artes</span>
                    <span className={styles['var1']}>Ed.Física</span>
                    <span className={styles['var2']}>Capoeira</span>
                </div>
                <div className={styles['cont-txt']}>
                    <span className={styles['var3']}>Integral</span>
                    <span className={styles['var4']}>Semi-Integral</span>
                    <span className={styles['var1']}>Reforço</span>
                </div>
                <div className={styles['cont-txt']}>
                    <span className={styles['var2']}>Prog. Socioemocional</span>
                    <span className={styles['var3']}>Informática</span>
                    <span className={styles['var4']}>Psicóloga</span>
                </div>
                <div className={styles['cont-txt']}>
                    <span className={styles['var2']}>SOE - Serviço de Orientação. </span>

                </div>
            </div>
            <div className={styles['cont-footer']}>
                <span className={styles['txt-footer']}>Conheça a nossa proposta pedagógica</span>
            </div>
        </section>
    )
}
