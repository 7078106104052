import React from 'react'
import styles from './styles.module.css'

export default function Bloco3() {
  return (
    <section className={styles['container']}>
        <div className={styles['cont-txt']}>
            <span className={styles['txt1']}>ESTRUTURA</span>
            <span className={styles['txt2']}>Conheça o Centro Educacional</span>
            <span className={styles['txt3']}>PRISCILA DA MATA</span>
        </div>
    </section>
  )
}
