import React from 'react'
import Bloco1 from './Bloco1/bloco1'
import Bloco2 from './Bloco2/Bloco2'
import Bloco3 from './Bloco3/bloco3'
import Bloco4 from './Bloco4/bloco4'
import { ContactUs } from './Bloco5/bloco5'
import Bloco6 from './Bloco6/Bloco6'
import Location from './Bloco7/Bloco7'




export default function home() {
  return (
    <div>
        <Bloco1/>
        <Bloco2/>
        <Bloco3/>
        <Bloco4/>
        <ContactUs/>
        <Bloco6/>
        <Location/>

    </div>
  )
}
