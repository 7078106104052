import React from 'react'
import styles from './styles.module.css'

export default function QuemSomos() {
    return (
        <section className={styles['container']}>
            <span className={styles['title']}>Quem Somos?</span>
            <span className={styles['txt']}>
                A Escola Centro Educacional Priscila da Mata abriu suas portas em 2022 com a
                Educação Infantil e Ensino Fundamental anos iniciais e nasceu da força e superação
                da professora Priscila Grasiela da Mata, educadora com histórico de longa data na
                busca de educação de qualidade, de construir uma escola, centrada na valorização de
                seu espaço enquanto recanto de SER e FAZER FELIZ! Um espaço sem fronteiras
                entre educadores, educandos e suas famílias, algo que se aproximasse do
                pensamento de Malaguzzi (Reggio Emília): “queremos uma escola agradável, em que
                crianças, professores e pais estejam à vontade. Uma amabilidade que ande de mãos
                dadas com a exigência”.
            </span>
            <span className={styles['txt']}>
                Hoje, a Escola oferece o Período Integral, Educação Infantil, Ensino Fundamental, nos
                turnos matutino e vespertino. O CePMA está sempre preocupado em preparar seus
                educandos para a vida e não apenas para dominar conteúdos curriculares. Para que
                isso aconteça ela busca contextualizar e fundamentar seu currículo em situações reais,
                com propostas significativas como meio de evitar o ensino mecanizado e desprovido
                de significado.
            </span>
            <span className={styles['txt']}>

                Ao longo de sua trajetória educacional ela tem procurado acompanhar o processo
                dinâmico de educar, estudando novas possibilidades, pesquisando caminhos que
                facilitassem a compreensão de seus educandos, aperfeiçoando sua prática
                pedagógica, inteirando-se de propostas de ensino inovadoras e ampliando seu olhar
                diante da sabedoria de cada criança que habita o CePMA.
            </span>
            <span className={styles['txt']}>
                Mantendo o foco no processo de desenvolvimento do educando e não apenas no
                resultado, a escola cultiva o respeito pelas diferenças individuais, valoriza os
                interesses das crianças, trabalha a autoconfiança, a persistência, a autonomia para
                que o educando se sinta motivado pelo prazer de ler, conhecer, refletir, planejar,
                sonhar e transformar seus sonhos em realidade.
            </span>
            <span className={styles['txt']}>
                Tudo isso, sem perder de vista a determinação em fazer dessa escola um lugar único,
                onde o educando se sinta bem, seguro de seus saberes, direitos, deveres e que se
                sinta parte dessa casa que foi idealizada para fazê-lo feliz.
            </span>
            <span className={styles['txt']}>
                Para atingir esses objetivos O Centro Educacional Priscila da Mata conta com uma
                equipe de profissionais experientes e capacitados no sentido de perceberem por trás
                dos conceitos, a importância de se estabelecer vínculos emocionais positivos com
                todos os educandos, redescobrindo conceitos, criando novos procedimentos e
                refletindo sobre sua prática em prol do ensinar para que todos possam atingir a tão
                almejada compreensão.
            </span>
            <span className={styles['txt']}>
                Assim a escola segue seu curso, construindo sua história dentro de um ambiente de
                constante atualização, onde toda a comunidade escolar assume o compromisso de
                zelar pela dimensão humanística em seus processos de ensino e de aprendizagem;
                buscando atender às necessidades básicas de cada ser de se sentir único, porém
                membro querido de um grupo, um ser participativo, produtivo, independente e confiante
            </span>
        </section >
    )
}
