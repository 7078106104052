import React from 'react'
import styles from './styles.module.css'
import { BsWhatsapp } from 'react-icons/bs';
export default function Wpp() {
    return (
        <section>
            <a href="https://wa.me/556185202905 " target="_blank" className={styles['link']}>
                <div className={styles['wpp']}>
                    <BsWhatsapp className={styles['btn']} />
                </div></a>
        </section>
    )
}
