import styles from './styles.module.css';
import Logo from '../../../assets/elements/logo.png';
import Arvore from '../../../assets/elements/arvore.png';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MdCardTravel, MdContactPhone, MdHome, MdQuestionMark } from 'react-icons/md';

import {
  AppBar,
  Avatar,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

export default function Navbar2() {
  const { isMobile } = useWindowSize();

  return <>{!!isMobile ? <SwipeableTemporaryDrawer /> : <WebNavBar />}</>;
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const isMobile = windowSize <= 768;

  //Pega o tamanho da janela em tempo real
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return { isMobile, windowSize };
}

type Anchor = 'left';

function SwipeableTemporaryDrawer() {
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => {
    return (
      <Box
        sx={{ width: 'auto' }}
        role='presentation'
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Stack direction='row' spacing={2} justifyContent='center' margin={2}>
          <Avatar alt='Remy Sharp' src={Arvore} />
        </Stack>
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <MdHome style={{ fontSize: '1.7rem' }} />
              </ListItemIcon>
              <ListItemText>
                <Link className={styles['item']} to='/'>
                  Home
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <MdQuestionMark />
              </ListItemIcon>
              <ListItemText>
                <Link className={styles['item']} to='/quemsomos'>
                  Quem Somos?
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <MdCardTravel />
              </ListItemIcon>
              <ListItemText>
                <Link className={styles['item']} to='/proposta'>
                  Proposta
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <MdContactPhone />
              </ListItemIcon>
              <ListItemText>
                <Link className={styles['item']} to='/territoriosdeaprendizagem'>
                  Territórios de Aprendizagem
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Box>
    );
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffffff',
      },
    },
  });

  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <Box sx={{ flexGrow: 1 }} key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
          <ThemeProvider theme={darkTheme}>
            <AppBar position='static' color='primary' enableColorOnDark>
              <Toolbar>
                <IconButton
                  size='large'
                  edge='start'
                  color='inherit'
                  aria-label='menu'
                  sx={{ mr: 2 }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                  <img src={Logo} style={{ width: '100px' }} />
                </Typography>
              </Toolbar>
            </AppBar>
          </ThemeProvider>
        </Box>
      ))}
    </div>
  );
}

function WebNavBar() {
  return (
    <section className={styles['container']}>
      <div className={styles['navbar']}>
        <img src={Logo} className={styles['logo']} />
        <ul className={styles['lista']}>
          <li className={styles['item-lista']}>
            <Link className={styles['item']} to='/quemsomos'>
              Quem Somos?
            </Link>
          </li>
          <li className={styles['item-lista']}>
            <Link className={styles['item']} to='/proposta'>
              Proposta
            </Link>
          </li>
          <li className={styles['item-lista']}>
            <Link className={styles['item']} to='/territoriosdeaprendizagem'>
              Territórios de Aprendizagem
            </Link>
          </li>
          <li className={styles['item-lista']}>
            <Link className={styles['item']} to='/'>
              Home
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
}
