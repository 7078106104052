import React from 'react'
import styles from './styles.module.css'

export default function Bloco1() {
  return (
    <section className={styles['container']}>
      <div className={styles['cont-bloco-txt']}>
        <span className={styles['first-txt']}>Seja muito</span>
        <span className={styles['second-txt']}>Bem-Vindo!</span>
        <span className={styles['subtitle']}>ao Centro Educacional Priscila da Mata</span>
        <span className={styles['tertiary-txt']}>Conheça nossa Escola</span>
      </div>
    </section>

  )
}
