import React from 'react'
import styles from './styles.module.css'
import Logo from '../../assets/elements/logo.png'

export default function Footer() {
  return (
    <section className={styles['container']}>
        <div className={styles['cont-logo']}>
          <img src={Logo} className={styles['logo']}/>
        </div>
        <div className={styles['telefone']}>
          <span className={styles['tell']}>
          (61) - 33106436
          </span>
          <span className={styles['tell']}> (61) - 985202905</span>
        </div>
        <div className={styles['copy']}>
          <span className={styles['txt']}>CopyRight: Centro Educaional Priscila da Mata</span>
        </div>
    </section>
  )
}
