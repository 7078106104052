import { Stack, Typography } from '@mui/material';
import { Box, display, fontSize } from '@mui/system';
import React from 'react';

import img1 from '../../assets/TerritoriosDeAprendizagem/img1.jpg';
import img2 from '../../assets/TerritoriosDeAprendizagem/img2.jpg';
import img3 from '../../assets/TerritoriosDeAprendizagem/img3.jpg';
import img4 from '../../assets/TerritoriosDeAprendizagem/img4.jpg';
import img5 from '../../assets/TerritoriosDeAprendizagem/img5.jpg';
import img6 from '../../assets/TerritoriosDeAprendizagem/img6.jpg';
import useWindowSize from '../../Hook/useWindowSize.tsx';

export default function TerritoriosDeAprendizagem() {
  const { isMobile } = useWindowSize();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? '1rem' : '10rem',
      }}
    >
      <Typography
        sx={{
          fontSize: isMobile ? '2rem' : '5rem',
          fontFamily: 'Mr Dafoe, cursive',
          color: 'var(--primary)',
          textDecoration: 'center',
        }}
      >
        Territórios de Aprendizagem
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.3rem',
          marginBottom: '2rem',
          textAlign: 'center',
          fontFamily: "'Century Gothic', sans-serif",
        }}
      >
        É a equipe pedagógica quem organiza os territórios de aprendizagem. Para isso, avalia-se o
        perfil do grupo de crianças, as necessidades coletivas e, sempre que possível, são levados
        em consideração aspectos como as necessidades individuais, o contexto sociocultural das
        famílias e da própria escola.
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.3rem',
          textAlign: 'center',
          fontFamily: "'Century Gothic', sans-serif",
        }}
      >
        A partir de então se planeja, se escolhe os materiais e se constrói os ambientes sempre
        pensando em proporcionar profundas experiências e descobertas por parte das crianças. “Ao
        contrário de uma perspectiva escolarizante, o professor imprime suas intenções pedagógicas
        na construção do território quando pensa ‘O que as crianças podem descobrir aqui se eu
        dispor os materiais dessa maneira?’, ‘E se eu colocar dessa outra forma?’ Também, usamos
        muito o território de aprendizagem natural, para ajudar na conservação do meio ambiente.
        Pois, depende diretamente da conscientização e da mudança de hábitos das pessoas. Tal
        mudança só é possível através da educação
      </Typography>
      <Box
        sx={{
          display: isMobile ? 'none' : 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '2rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <img
            src={img1}
            style={{
              padding: '0.5rem',
              backgroundColor: 'var(--primary)',
              borderRadius: '30px',
              width: '250px',
              height: '100%',
              marginRight: '2rem',
            }}
          />
          <img
            src={img3}
            style={{
              padding: '0.5rem',
              backgroundColor: 'var(--primary)',
              borderRadius: '30px',
              width: '250px',
              height: '100%',
              marginRight: '2rem',
            }}
          />
          <img
            src={img2}
            style={{
              padding: '0.5rem',
              backgroundColor: 'var(--primary)',
              borderRadius: '30px',
              width: '300px',
              height: '100%',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            marginTop: '2rem',
          }}
        >
          <img
            src={img4}
            style={{
              padding: '0.5rem',
              backgroundColor: 'var(--primary)',
              borderRadius: '30px',
              width: '250px',
              height: '100%',
              marginRight: '2rem',
            }}
          />
          <img
            src={img5}
            style={{
              padding: '0.5rem',
              backgroundColor: 'var(--primary)',
              borderRadius: '30px',
              width: '250px',
              height: '100%',
              marginRight: '2rem',
            }}
          />
          <img
            src={img6}
            style={{
              padding: '0.5rem',
              backgroundColor: 'var(--primary)',
              borderRadius: '30px',
              width: '300px',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
