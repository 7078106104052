
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import styles from './styles.module.css'

export default function Location() {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBXs0sblLvT3g_Unl5uHqSYy3yA7kEOG5s"
    });

    if (!isLoaded) return <div>Loading...</div>;
    return <Map />;
}
const center = { lat: -16.00128779999918, lng: -48.03025449999962 };

function Map() {

    return (
        <section className={styles['container']}>
            <div className={styles['cont-txt']}>
                <span className={styles['title']}>Localização</span>
                <span className={styles['txt']}>Conheça a localização das instalações do Colégio </span>
            </div>
            <GoogleMap
                zoom={17}
                center={center}
                preventGoogleFontsLoading={true}
                mapContainerClassName={styles['map-container']}>
                <Marker position={center} />
            </GoogleMap>
            <div className={styles['cont-btn']}>
                <a className={styles['link']} href='https://goo.gl/maps/batDo6vXRmWhPDHR6' target='_blank' rel="noreffer">
                    <button className={styles['btn']}>Abrir no Maps</button>
                </a>
            </div>
        </section>
    )
}
