import React from 'react'
import { Routes, Route, HashRouter } from 'react-router-dom'
import Home from './Pages/Home/home'
import Proposta from './Pages/Proposta'
import QuemSomos from './Pages/QuemSomos'
import TerritoriosDeAprendizagem from './Pages/TerritoriosDeAprendizagem/TerritoriosDeAprendizagem.tsx'

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/quemsomos' element={<QuemSomos />} />
      <Route path='/proposta' element={<Proposta />} />
      <Route path='/territoriosdeaprendizagem' element={<TerritoriosDeAprendizagem />} />
    </Routes>
  )
}
