import React from 'react'
import styles from './styles.module.css'
import Img from '../../../assets/Fotos/img6.jpg'

export default function Bloco4() {
    return (
        <section className={styles['container']}>
            <div className={styles['cont-txt']}>
                <span className={styles['title']}>Estude Conosco</span>
                <span className={styles['txt']}>Você está a um formulário de</span>
                <span className={styles['txt']}>distância do nosso universo de</span>
                <span className={styles['txt']}>aprendizado e conhecimento</span>
                <div className={styles['cont-btn']}>
                    <span className={styles['sub1']}>Começar</span>
                    <span className={styles['sub2']}>Agora</span>
                </div>
            </div>
            <div className={styles['cont-img']}>
                <img src={Img} className={styles['img']}/>
            </div>

        </section>
    )
}
