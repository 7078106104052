import React from 'react'
import styles from './styles.module.css'

export default function Proposta() {
    return (
        <section className={styles['container']}>
            <div className={styles['cont-title']}>
                <span className={styles['title']}>Proposta Pedagógica</span>
                <span className={styles['sub-title']}>(abordagem Reggio Emília/Educação Ambiental)</span>
            </div>
            <div className={styles['cont-txt']}>
                <span className={styles['title-txt']}>Reggio Emília na prática</span>
                <span className={styles['txt']}>
                    É uma pedagogia focada em desenvolver as habilidades da criança, criando um
                    sujeito independente, autônomo e confiante em sua capacidade de ser um grande
                    líder, independente dos caminhos que escolha na vida.
                    Para isso, todo o conteúdo é trabalhado de maneira interdisciplinar, valorizando o
                    esforço em equipe. O aluno é incentivado a buscar as informações, refletir
                    criticamente sobre o que lê e apresentar suas conclusões para a turma em debates
                    e assembleias e os maravilhosos territórios de aprendizagem.
                </span>
                <span className={styles['txt']}>
                    As dinâmicas são elaboradas de acordo com a faixa etária da criança. Nos primeiros
                    anos da escolarização, por exemplo, é comum que as brincadeiras sejam adotadas
                    como ferramenta de aprendizado.
                </span>
                <span className={styles['txt']}>
                    “Somos a única escola voltada especificamente para a educação ambiental na
                    região”. À primeira vista, o CePMA- Centro Educacional Priscila da Mata
                    impressiona com seus 42 mil hectares e espaços: Lagoa, fazendinha, curral, hortas,
                    campo de areia, casinha na árvore, piscina, árvores frutíferas e muito mais. Adentrar
                    o espaço é ingressar em um universo, sobretudo, pedagógico, mas criado para
                    encantar. Localizada entre o Gama e Santa Maria na área rural Alagados.
                </span>
                <span className={styles['txt']}>
                    Criada em 2022, foi iniciada suas obras em dezembro de 2021 e em fevereiro do
                    próximo ano, já iniciamos o ano letivo. Foi erguida num tempo recorde de 2 meses.
                    A partir de um projeto que já estava no coração da nossa diretora Priscila, com o
                    objetivo de envolver a comunidade escolar de ensino com as questões ambientais,
                    o CePMA será em breve, um dos Centro de Referência em Educação Ambiental
                    da Secretaria de Educação.
                </span>
                <span className={styles['txt']}>
                    A proposta do CePMA faz parte do princípio de que um espaço educador sustentável
                    pode e deve contribuir para a melhoria da relação de aprendizagem por meio de
                    processos educativos permanentes e continuados, capazes de sensibilizar o
                    estudante e a coletividade para a construção de conhecimentos, valores,
                    habilidades, atitudes e competências que incentivem a formação de uma sociedade
                    de direitos, ambientalmente justa e sustentável.
                </span>
            </div>
            <div className={styles['cont-title']}>
                <span className={styles['title']}>Educação Infantil</span>
            </div>
            <div className={styles['cont-txt']}>
                <span className={styles['title-txt']}>Reggio Emília na prática</span>
                <span className={styles['txt']}>
                    Nossa proposta pedagógica para a educação infantil é pautada na Pedagogia da
                    Escuta e nos ideais Reggianos de Educação. Com um olhar sensível para a infância,
                    acreditamos que cada criança é protagonista de seu desenvolvimento e o constrói a
                    partir das relações com o meio em que vive, transformando as experiências
                    cotidianas em novas descobertas.
                </span>
                <span className={styles['txt']}>
                    A infância precisa de espaço! Sendo assim, nossos espaços são divididos entre sala
                    de aula e salas ambientes que dispõe de intenções, proporcionando uma dinâmica
                    de troca enriquecedora e aprendizagem constante. Em todos os cantos da escola, as
                    crianças podem se expressar das mais criativas maneiras, sendo o diálogo
                    considerado aspecto fundamental para ampliação do conhecimento. As crianças, ao
                    participarem de Projetos Interdisciplinares com propostas lúdicas e instigantes, com
                    ações coletivas e construções compartilhadas, desenvolvem conhecimento das
                    áreas específicas.
                </span>
                <span className={styles['txt']}>
                    Valorizamos o pensar criativo e autônomo, garantindo que as crianças possam
                    brincar, fazer escolhas, dar opiniões e refletir sobre suas ações, respeitando as
                    potencialidades e habilidades de cada um.
                </span>
                <span className={styles['txt']}>
                    As situações didáticas consideram as hipóteses das crianças, despertando sua
                    curiosidade e o desejo de aprender, investigar e organizar seus conhecimentos.
                </span>
                <span className={styles['txt']}>
                    O Projeto sobre Educação ambiental o, é iniciado na Educação Infantil, seguindo os
                    temas dos projetos interdisciplinares e com destaque para a musicalização. As aulas
                    são diárias, e focadas no desenvolvimento da fala e aquisição de vocabulário, sendo
                    assim, a adesão de uma segunda língua é adquirida de maneira divertida e natural.
                </span>
            </div>
            <div className={styles['cont-title']}>
                <span className={styles['title']}>Fundamental 1</span>
                <span className={styles['sub-title']}>(abordagem Reggio Emília/Educação Ambiental)</span>
            </div>
            <div className={styles['cont-txt']}>
                <span className={styles['title-txt']}>Reggio Emília na prática</span>
                <span className={styles['txt']}>
                    A escola visa desenvolver as habilidades integrais e as múltiplas competências do
                    aluno. A proposta educativa permite uma formação integral, respeitando as
                    diferentes formas de aprender a conhecer, a ser, fazer e conviver.
                    Nossos alunos são frequentemente desafiados a analisar, fazer escolhas,
                    construir, imaginar, desconstruir, experimentar, questionar e avaliar os
                    conteúdos disciplinares em um processo contínuo de interação.
                </span>
                <span className={styles['txt']}>
                    Respeitando cada disciplina, o conteúdo é ricamente ilustrado. Valores como ética,
                    cidadania, pluralidade cultural, cuidados com o meio ambiente e relações culturais
                    fazem parte da ampliação da nossa proposta pedagógica, além da formação do
                    desenvolvimento crítico e criativo do aluno. Para estimulação de todas essas e
                    outras inúmeras manifestações da aprendizagem existe um professor mediador em
                    busca de uma constante transformação na forma de educar
                </span>
                <span className={styles['txt']}>
                    Como solução educacional, utilizamos o material da Positivo, que privilegia
                    experiências significativas, leituras contextualizadas e situações desafiadoras e
                    colaborativas, além de garantir a alfabetização e os multiletramentos. Promovendo
                    também a construção dos conceitos estruturantes e o desenvolvimento de
                    habilidades e domínios cognitivos através dos recursos tecnológicos relevantes,
                    plataforma de aprendizagem virtual e material didático físico hiper atualizado.
                </span>
                <span className={styles['txt']}>
                    Em parceria com a Editora Pleno, proporcionamos também, aulas socioemocionais
                    semanais com enfoque no aluno, esse enfoque é tratado com relações
                    interpessoais, com meditação, aulas práticas, diálogos e desafios a serem
                    solucionados. Sempre que possível as crianças levam para casa algumas tarefas
                    para serem feitas com as famílias, com isso, percebemos que o enfoque
                    socioemocional tratado na escola o, abrange também família, professor e aluno,
                    consequentemente, transformando o mundo.
                </span>
            </div>

        </section>
    )
}
