
import { HashRouter } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar2 from "./Components/NavBar/navbar2/navbar2.tsx";
import Wpp from "./Components/Wpp";
import Rotas from "./router";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Navbar2 />
        <Rotas />
        <Wpp />
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
