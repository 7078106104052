import React from 'react'
import styles from './styles.module.css'
import Img1 from '../../../assets/Fotos/img1.jpg'
import Img2 from '../../../assets/Fotos/img2.JPG'
import Img3 from '../../../assets/Fotos/img6.jpg'

export default function Bloco2() {
    return (
        <section className={styles['container']}>
            <div className={styles['cont-txt']}>
                <span className={styles['txt1']}>Proposta</span>
                <span className={styles['txt2']}>Educacional</span>
                <span className={styles['txt3']}>DO BERÇÁRIO AO SEXTO ANO</span>
            </div>
            <div className={styles['cont-img']}>
                <div className={styles['cont-img1']}>
                    <span className={styles['txt-image']}>Berçário</span>
                    <img src={Img2} className={styles['img']} />
                    <span className={styles['txt-image-responsive']}>Berçário</span>
                </div>
                <div className={styles['cont-img2']}>
                    <img src={Img1} className={styles['img']}/>
                    <span className={styles['txt-image']}>Educação Infantil</span>
                    <span className={styles['txt-image-responsive']}>Educação Infantil</span>
                </div>
                <div className={styles['cont-img3']}>
                    <span className={styles['txt-image']}>Educação Fundamental</span>
                    <img src={Img3} className={styles['img']}/>
                    <span className={styles['txt-image-responsive']}>Ensino Fundamental</span>
                </div>
            </div>
        </section>
    )
}
